@import "../../../../styles/_settings.scss", "../../../../styles/functions/_util.scss";

$colors-viewer-item-margin: rem-calc(2px);

.color-viewer-item {
  line-height: 0;
  font-size: 0;
  display: inline-block;
  padding: rem-calc(1px);
  /*margin: rem-calc(1px);*/
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px transparent solid;
  vertical-align: middle;
}

.color-viewer-item, .color-viewer-inner {
  border-radius: $border-radius-small;
  position: relative;
  cursor: pointer;
}

.color-viewer-alt .color-viewer-inner {
  width: auto !important;
  height: auto !important;
  padding: 10px;
  font-size: 12px;
  line-height: 1;
  background: transparent !important;
  border: 0;
}

.color-viewer-inner {
  display: inline-flex;
  background-repeat: no-repeat;
  margin: 2px;
  background-size: 100% 100%;
  border-width: 1px;
  border-style: solid;
}

.color-viewer-header {
  font-size: $font-size-normal;
  margin-bottom: $vertical-interval-xsmall;
}

.carousel-colors-wrap {
  text-align: center;

  .carousel-inner {
    padding: 0;
    /*padding: 0 1px;*/
  }
}

.color-viewer-list,
.color-viewer {
  display: flex;
  flex-wrap: wrap;
  /*justify-content: center;*/
}

.carousel-colors {
  padding: 0 rem-calc(20px);

  &.carousel-nav-not-show {
    padding: 0;
  }

  .color-viewer-list {
    flex-wrap: nowrap;
  }
}

.carousel-colors:not(.carousel-nav-not-show) .carousel-inner,
.products-view-tile .carousel-colors .carousel-inner {
  margin-left: auto;
  margin-right: auto;
}


.products-view .carousel-colors.carousel-initilized {
  display: block;
  max-width: 100%;
}

.products-view-tile .carousel-colors.carousel-initilized {
  display: block;
}

.color-viewer-item-block {
  border: 1px transparent solid;
  display: inline-flex;
  cursor: pointer;
  border-radius: .1875rem;
  padding: 2px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  vertical-align: middle;
}

.color-viewer-item-wrap {
  padding: 2px;
  vertical-align: middle;
  box-sizing: border-box;
  flex-shrink: 0;
}

.color-viewer-item-name {
  font-size: 13px;
  box-sizing: border-box;
  line-height: normal;
}

.color-viewer-inner + .color-viewer-item-name {
  padding-left: 5px;
}

.color-viewer__color-selected-name {
  flex-basis: 100%;
}

.color-viewer-item-disabled {
  position: relative;
  pointer-events: none;
  color: #94969b;
}

%icon-disabled {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  display: block;
  cursor: default;
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.7);
  background-size: 100% 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 460.775 460.775' style='enable-background:new 0 0 460.775 460.775' xml:space='preserve'%3E%3Cpath fill='red' d='M285.08 230.397 456.218 59.27c6.076-6.077 6.076-15.911 0-21.986L423.511 4.565a15.55 15.55 0 0 0-21.985 0l-171.138 171.14L59.25 4.565a15.551 15.551 0 0 0-21.985 0L4.558 37.284c-6.077 6.075-6.077 15.909 0 21.986l171.138 171.128L4.575 401.505c-6.074 6.077-6.074 15.911 0 21.986l32.709 32.719a15.555 15.555 0 0 0 21.986 0l171.117-171.12 171.118 171.12a15.551 15.551 0 0 0 21.985 0l32.709-32.719c6.074-6.075 6.074-15.909 0-21.986L285.08 230.397z'/%3E%3C/svg%3E");

}

.color-viewer--icon, .color-viewer--iconandtext {
  .color-viewer-item-disabled .color-viewer-inner::after {
    @extend %icon-disabled
  }
}

.colors-viewer--in-filter {
  &.color-viewer--iconandtext,
  &.color-viewer--text {
    justify-content: start;

    .color-viewer-item-wrap {
      flex-grow: 1;
      flex-basis: 100%;
    }

    .color-viewer-item-block {
      justify-content: start;
    }

    .color-viewer-item-selected {
      border-color: transparent !important;
    }

    .color-viewer-item-wrap,
    .color-viewer-item-block {
      padding: 0;
    }

    &.color-viewer--text {
      .color-viewer-item-wrap {
        padding-top: 3px;
        padding-bottom: 3px;
      }
    }
  }

  &.color-viewer--icon{
    margin-left: -8px;
  }
}


.select-custom {
  .color-viewer-item-block,
  .color-viewer-item-wrap {
    border: 0;
    padding: 0;
  }

  .color-viewer-item-name {
    font-size: inherit;
  }

  .color-viewer-inner {
    max-width: 50px;
    max-height: 50px;
  }
}

[ng-transclude-slot="colorsViewerItemBefore"]:empty {
  display: none;
}